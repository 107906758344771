
import { uploadFile } from '@/api/attachedFile';
import { getBusiness } from '@/api/business';
import { addBusinessApply } from '@/api/businessApply';
import { UserModule } from '@/store/user';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { parseConcatNumber } from '@/filters';
import { getRegionList } from '@/api/region';

@Component({
  name: 'BusinessApply',
  components: {
  },
})
export default class extends Vue {
  @Watch('formData.termsAgreeStatus')
  private handleChangeTermsAgree() {
    this.checkAgreeStatus();
  }

  @Watch('formData.alarmAgreeStatus')
  private handleChangeAlarmAgree() {
    this.checkAgreeStatus();
  }

  mounted() {
    if (this.isLogined) {
      this.getBusiness();
      this.getRegionList();
    } else {
      this.$router.push({ name: 'Login' });
    }
  }

  get isLogined() {
    return UserModule.token !== '';
  }

  private allAgree = false;

  private regionList = [];

  private parentRegion = {
    name: '',
    children: [],
  };

  private formData: any = {
    companyName: '',
    companyNo: '',
    coperateNo: '',
    companyCreateDate: '',
    bossName: '',
    bossTel: '',
    bossGender: '',
    bossBirth: '',
    managerName: '',
    managerTel: '',
    companyAddress: '',
    companyAddressDetail: '',
    businessItem: '',
    previousYearSale: '0',
    previousYearEmployment: '0',
    files: [],
    termsAgreeStatus: false,
    alarmAgreeStatus: false,
    managerEmail: '',
    managerEmail2: '',
    managerEmail3: '',
    businessType: '',
    businessCategory: '',
    establishDate: '',
    investmentName: '',
    investmentDate: '',
    investmentAmount: '0',
  }

  private years() {
    const year = new Date().getFullYear();
    const years = Array.from({ length: 70 }, (v, i) => year - 70 + i + 1);
    years.reverse();
    return years;
  }

  private getBusiness() {
    getBusiness(this.$route.params.businessIdx).then((res) => {
      if (!res.data.progressStatus) {
        alert('신청마감된 지원사업입니다.');
        this.$router.push({ name: 'BusinessDetail' });
      } else {
        res.data.fileInfos.forEach((fileInfo: any) => {
          this.formData.files.push({
            businessFileIdx: fileInfo.idx,
            fileName: fileInfo.fileName,
            fileUid: '',
            originalName: '',
          });
        });
      }
    });
  }

  private applyActive() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        addBusinessApply(this.$route.params.businessIdx, this.formData).then(() => {
          alert('지원사업 신청을 완료했습니다.');
          this.$router.push({ name: 'BusinessDetail' });
        }).catch((error) => {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          } else {
            alert('지원사업 신청을 실패했습니다.');
          }
        });
      }
    });
  }

  private handleChangeAllAgree(e: any) {
    this.formData.termsAgreeStatus = e.target.checked;
    this.formData.alarmAgreeStatus = e.target.checked;
  }

  private checkAgreeStatus() {
    if (this.formData.termsAgreeStatus && this.formData.alarmAgreeStatus) {
      this.allAgree = true;
    } else {
      this.allAgree = false;
    }
  }

  private handleChangeFile(e: any, index: number) {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 100) {
      alert('최대 100MB 까지 업로드 가능합니다.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    uploadFile('client/business/apply', formData).then((res) => {
      this.formData.files[index].fileUid = res.data.uid;
      this.formData.files[index].originalName = res.data.originalName;
    });
  }

  private handleClickRemoveFile(index: number) {
    this.formData.files[index].fileUid = '';
    this.formData.files[index].originalName = '';
  }

  private formatBizNumber() {
    this.formData.companyNo = this.formData.companyNo.replace(/[^0-9]/g, '') // 숫자 이외의 문자 제거
      .replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'); // 사업자번호 형식으로 변경
  }

  private formatBizNumber2() {
    this.formData.coperateNo = this.formData.coperateNo.replace(/[^0-9]/g, '') // 숫자 이외의 문자 제거
      .replace(/(\d{6})(\d{7})/, '$1-$2'); // 법인등록번호 형식으로 변경
  }

  private formatBossTel() {
    this.formData.bossTel = parseConcatNumber(this.formData.bossTel);
  }

  private formatManagerTel() {
    this.formData.managerTel = parseConcatNumber(this.formData.managerTel);
  }

  private getRegionList() {
    getRegionList().then((res) => {
      this.regionList = res.data;
    });
  }

  private handleChangeRegion() {
    const index = this.regionList.findIndex((region: any) => region.name === this.formData.companyAddress);
    if (index > -1) this.parentRegion = this.regionList[index];
    else this.parentRegion = { name: '', children: [] };
  }
}
